import { IonCol, IonContent, IonGrid, IonItem, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import { Link } from 'react-router-dom';
import { fetchLinks, getLinks } from '../../services/account/group-links.service';

const BDT = new Intl.NumberFormat('en-IN');
const GroupLinks: React.FC = () => {
    const [loading, setLoadling] = useState(false)
    const response = getLinks()
    const [items, setItems] = useState(response ? response.groupLinks : null)
    useEffect(() => {
        loadData()
    }, [])
    function loadData() {
        const local = getLinks()
        if (local) {
            setItems(local.groupLinks)
        } else {
            setLoadling(true)
        }
        fetchLinks().then((res) => {
            if (res) {
                setItems(res.groupLinks)
            }
        }).finally(() => setLoadling(false))
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>No Transaction Found</h1>
                        </div>}
                        {items && <div className='page-container'>
                            <div className='title-bar' style={{ marginTop: 60, marginBottom: 50 }}>
                                <h1>Facebook Group Links</h1>
                            </div>
                            <IonGrid>
                                <IonRow>
                                    {items.map((item, index) => {
                                        return <IonCol size='12' sizeMd='6' sizeLg='4' key={index}>
                                            <IonItem href={item.url} target='_blank'>{item.title}</IonItem>
                                        </IonCol>
                                    })}
                                </IonRow>
                            </IonGrid>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default GroupLinks;
