import { useEffect, useState } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { apiBaseUrl, baseUrl } from '../../configs/app';
import './Slides.scss';
import { getProductImgUrl } from '../../functions/product.functions';
import ProgressiveImg from '../ProgressiveImg';
type Slide = {
    url?: string
    image: string
}
const Slides: React.FC = () => {
    const localData = window.localStorage.getItem("slides")
    const [slides, setSlides] = useState((localData ? JSON.parse(localData) : []) as Slide[])
    function fetchSlides() {

        const requestOptions = {
            method: 'GET',
            cache: "no-store",
            redirect: 'follow'
        };
        const url = apiBaseUrl + 'slides'
        fetch(url, requestOptions as RequestInit).then((res) => res.json())
            .then((res) => {
                // console.log(res)
                window.localStorage.setItem("slides", JSON.stringify(res))
                setSlides(res)
            })
    }
    useEffect(() => {
        fetchSlides()
    }, [])

    return <>{
        slides && <Swiper
            autoplay={{
                delay: 3000
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}>
            {slides.map((item, index) => <SwiperSlide key={index}>
                <ProgressiveImg src={baseUrl+"storage/uploads/"+item.image+".webp"}/>
            </SwiperSlide>)}
        </Swiper>
    }</>
};

export default Slides;
