import { IonButton, IonContent } from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash } from '@fortawesome/free-solid-svg-icons';

const PaymentFailed: React.FC = () => {
    return (
        <IonContent id='standalone-page' fullscreen>
            <div id='standalone-page'>
                <div className='box'>
                    <FontAwesomeIcon style={{ marginTop: 50, marginBottom: 50 }} color='red' size='10x' icon={faCarCrash} />
                    <h1 style={{ marginBottom: 50 }}>Your Payment Was Failed.</h1>
                    <IonButton routerLink={appSubDir} fill='outline'>
                        Go To Home
                    </IonButton>
                </div>
            </div>
        </IonContent>
    );
};

export default PaymentFailed;
