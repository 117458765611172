import { IonButton, IonContent, IonFooter, IonHeader, IonItem, IonModal, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { appSubDir } from '../../configs/app';
import { useEffect, useRef, useState } from 'react';
import { fetchCategories, localCategories } from '../../services/products/all-category.service';
import Category from "../../types/category.type";
type Props = {
    title: string
    excludedCatID?: number
}
const AllCategories: React.FC<Props> = ({ title, excludedCatID }) => {
    const modal = useRef<HTMLIonModalElement>(null)
    const [items, setItems] = useState(localCategories())
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if(!items){
            setLoading(true)
        }
        fetchCategories().then((items) => {
            setItems(items)
        }).finally(()=>setLoading(false))
    }, [excludedCatID])
    function filter(items:Category[]){
        return excludedCatID ? items.filter((item) => item.id !== excludedCatID) : items
    }
    if (!items) {
        return null
    }

    return <>
        <IonButton onClick={() => modal.current?.present()} fill='outline'>{title}</IonButton>
        <IonModal ref={modal}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{title}</IonTitle>
                    {window.screen.width > 425 && <IonButton onClick={() => modal.current?.dismiss()} slot='end' fill='clear'>Close</IonButton>}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {loading && <div className='box-center'><IonSpinner /></div>}
                {filter(items).map((item, index) => <IonItem key={index} routerLink={appSubDir + "categories/" + item.id}>{item.title}</IonItem>)}
            </IonContent>
            {window.screen.width <= 425 && <IonFooter>
                <IonButton onClick={() => modal.current?.dismiss()} expand='block' fill='clear'>Close</IonButton>
            </IonFooter>}
        </IonModal>
    </>
};

export default AllCategories;
