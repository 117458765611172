import { apiBaseUrl } from "../../configs/app";
import Invoice from "../../types/shop/invoice.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";

function getKey(id: string) {
    return CryptoService.hash("invoice-" + id)
}
export function getInvoice(id: string) {
    const localStorageKey = getKey(id)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Invoice : null)
}
export function fetchInvoice(id: string) {
    const localStorageKey = getKey(id)
    const url = apiBaseUrl + `invoices/${id}`
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    } as RequestInit;
    return fetch(url, requestOptions).then((res) => res.json())
        .then((res: Invoice) => {
            if (res && res.id) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res as Invoice
            } else {
                return null
            }
        })
}
