import { IonCol, IonContent, IonRow, IonText } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';
import { fetchWishlistProducts, getWishlistProducts } from '../../services/account/wishlist.service';
import { useAppContext } from '../../services/app-context.service';

const WishlistPage: React.FC = () => {
    const { user } = useAppContext()
    const productResponse = getWishlistProducts()
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    function loadProducts(page: number) {
        const local = getWishlistProducts(page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchWishlistProducts(page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts(1)
    }, [])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        <div className='flash-sale mb-50'>
                            <section className='title'>
                                <IonText color="primary" className='f'>My</IonText>
                                <IonText color="danger" className='s'>{user.role_id == 2 ? "Shop" : "Wishlist"}</IonText>
                            </section>
                        </div>
                        <div style={{ marginTop: 30 }}>
                            {items && <ProductsGrid title='Products' products={items} hideHeader={true} />}
                            {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default WishlistPage;
