import { IonButton, IonIcon } from '@ionic/react';
import { codeWorkingOutline } from 'ionicons/icons';
import './Pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
const slideOpts = {
    slidesPerView: 7,
    spaceBetween: 0,
    loop:true,
    breakpoints: {
        // when window width is >= 320px
        425: {
            slidesPerView: 7,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 14,
            spaceBetween: 0
        },
        // when window width is >= 640px
        1440: {
            slidesPerView: 20,
            spaceBetween: 0
        },
        1600: {
            slidesPerView: 25,
            spaceBetween: 0
        }
    }
};
type Props = {
    active:number
    disabled:boolean
    buttonCount:number
    onClick:(pageNo:number)=>void
}
const Pagination: React.FC<Props> = ({buttonCount,onClick,disabled,active}) => {
    return <div className='pagination'>
        <Swiper {...slideOpts}>
            {([...Array(buttonCount)]).map((item, index) => {
                const pageNo = index + 1
                return <SwiperSlide key={index}>
                    <IonButton key={index}
                        disabled={disabled}
                        onClick={() => onClick(pageNo)}
                        fill={active === pageNo ? 'solid' : 'outline'} color='primary'>
                        {pageNo}
                    </IonButton>
                </SwiperSlide>
            })}
        </Swiper>
        <h6 className='text-center'>* Swipe <IonIcon icon={codeWorkingOutline} /> To See More pages</h6>
    </div>
};

export default Pagination;
