import { apiConfig } from "../../configs/app";
import { SimpleProduct } from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    current_page: number
    data: SimpleProduct[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
// type RequestBody = {
//     subcategory_id?: string
//     new_arrivals?: "new_arrivals"
//     best_selling?: "best_selling"
//     price_type?: "Price" | "Discount"
//     price_from?: string
//     price_to?: string
//     random_order?: string
//     excluded_product?: string
//     img?: "web" | "regular"
//     page?: string
//     items_per_page?: string
//     flush_product?: "flush_product"
//     category_id?: string
// }
function getKey(params: URLSearchParams) {
    return CryptoService.hash("products-" + params.toString())
}
export function getProducts(params: URLSearchParams) {
    const localStorageKey = getKey(params)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchProducts(params: URLSearchParams) {
    const localStorageKey = getKey(params)
    const urlPart = `?${params.toString()}`
    let promise: Promise<Response>
    if (loginService.currentUser.token) {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        } as RequestInit;
        promise = fetch(apiConfig.privateProducts + urlPart, requestOptions)
    } else {
        promise = fetch(apiConfig.products + urlPart)
    }
    return promise.then((res) => res.json())
        .then((res: any) => {
            if (res.products && res.products.total > 0) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res.products)))
                return res.products as ApiResponse
            } else {
                return null
            }
        })
}
