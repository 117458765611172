import { IonBadge, IonButton, IonIcon} from "@ionic/react";
import { heartOutline } from "ionicons/icons";
import { useAppContext } from "../../services/app-context.service";
import { appSubDir } from "../../configs/app";

const WishlistButton: React.FC = () => {
    const { user, cart } = useAppContext()
    if(!user.token){
        return null
    }
    return <>
        <IonButton fill='clear' id="btn-min-cart" routerLink={appSubDir + (user.role_id == 2 ? "my-shop" : "wishlist")}>
            <IonIcon size='large' icon={heartOutline} />
        </IonButton>
    </>
};
export default WishlistButton
