import { useRef, useState } from 'react';
import './ZoomableImg.scss'
import { IonButton, IonIcon } from '@ionic/react';
import { downloadOutline } from 'ionicons/icons';

type Props = {
    src: string;
}

const ZoomableImg: React.FC<Props> = ({ src }) => {
    const figRef = useRef<HTMLElement>(null)
    const [backgroundPosition, SetBackgroundPosition] = useState('0% 0%')
    const handleMouseMove = (e: any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        SetBackgroundPosition(`${x}% ${y}%`)
    }
    return (
        <figure ref={figRef} onMouseMove={handleMouseMove} className='zoomable' style={{ backgroundImage: `url(${src})`, backgroundPosition }}>
            <img src={src} alt="" />
            <IonButton href={src} download='' target='_blank' shape='round' color='light' size='small'><IonIcon icon={downloadOutline} /></IonButton>
        </figure>
    );
};

export default ZoomableImg;
