import { IonButton, IonText } from '@ionic/react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {SimpleProduct} from '../../types/product.type';
import ProductCard from '../Products/ProductCard';
import "./FlashSale.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import CustomCountDown from '../CustomCountDown';
import { appSubDir } from '../../configs/app';
import { fetchProducts, getProducts } from '../../services/products/flash-sale-products.service';
import { useEffect, useState } from 'react';

const slideOpts = {
    autoplay: {
        delay: 5000
    },
    // loop: true,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 3,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 6,
            spaceBetween: 10
        }
    }
};
const FlashSale: React.FC = () => {
    const productResponse = getProducts()
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    function loadProducts(page: number) {
        const local = getProducts(page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchProducts(page).then((res) => {
            if (res) {
                setItems(res.data)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts(1)
    }, [])
    return (
        <div className='flash-sale'>
            <section className='title'>
                <IonText className='f'>Top</IonText>
                <FontAwesomeIcon color='#D62447' size="2x" icon={faBolt} />
                <IonText color="danger" className='s'>Deals</IonText>
            </section>
            <CustomCountDown />
            <hr />
            {items && <Swiper {...slideOpts} modules={[Autoplay]}>
                {items.map((item, index) => <SwiperSlide key={index}>
                    <ProductCard product={item} />
                </SwiperSlide>)}
            </Swiper>}
            <div className='text-center' style={{marginTop:20}}>
                <IonButton color='danger' fill='outline' routerLink={appSubDir+"flash-sale"}>
                    View All
                </IonButton>
            </div>
        </div>
    );
};

export default FlashSale;
