import { Redirect, Route } from 'react-router-dom';
import {
  IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { appSubDir } from '../configs/app';
import Home from '../pages/Home';
import Contact from '../pages/Contact';
import FlashSale from '../pages/products/FlashSale';
import Shop from '../pages/products/Shop';
import Category from '../pages/products/Category';
import NotFound from '../pages/NotFound';
import Subject from '../pages/products/Subject';
import Product from '../pages/products/Product';
import Author from '../pages/products/Author';
import NewArrival from '../pages/products/NewArrival';
import PaymentSuccess from '../pages/payments/PaymentSuccess';
import PaymentVerify from '../pages/payments/PaymentVerify';
import PaymentCancelled from '../pages/payments/PaymentCancelled';
import PaymentFailed from '../pages/payments/PaymentFailed';
import GuestUserInvoice from '../pages/GuestUserInvoice';
import OrderPlaced from '../pages/payments/OrderPlaced';
import Search from '../pages/products/Search';
import CommingSoon from '../pages/CommingSoon';


const PublicRoutes: React.FC = () => (
  <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path={appSubDir}>
          <Home />
        </Route>
        <Route exact path={appSubDir+"contact"}>
          <Contact />
        </Route>
        <Route exact path={appSubDir+"shop"}>
          <Shop />
        </Route>
        <Route exact path={appSubDir+"flash-sale"}>
          <FlashSale />
        </Route>
        <Route exact path={appSubDir+"new-arrival"}>
          <NewArrival />
        </Route>
        <Route exact path={appSubDir+"product/:slug/item-:ID"}>
          <Product />
        </Route>
        <Route exact path={appSubDir+"search/:searchKey"}>
          <Search />
        </Route>
        <Route exact path={appSubDir+"categories"}>
          <CommingSoon />
        </Route>
        <Route exact path={appSubDir+"printing-service"}>
          <CommingSoon />
        </Route>
        <Route exact path={appSubDir+"authors"}>
          <CommingSoon />
        </Route>
        <Route exact path={appSubDir+"categories/:catID"}>
          <Category />
        </Route>
        <Route exact path={appSubDir+"authors/:authorID"}>
          <Author />
        </Route>
        <Route exact path={appSubDir+"categories/:catID/subjects/:subjectID"}>
          <Subject />
        </Route>
        <Route exact path={appSubDir+"payment-verify/:valID"}>
          <PaymentVerify />
        </Route>
        <Route exact path={appSubDir+"order-placed"}>
          <OrderPlaced />
        </Route>
        <Route exact path={appSubDir+"payment/success"}>
          <PaymentSuccess />
        </Route>
        <Route exact path={appSubDir+"payment/failed"}>
          <PaymentFailed />
        </Route>
        <Route exact path={appSubDir+"payment/cancelled"}>
          <PaymentCancelled />
        </Route>
        <Route exact path={appSubDir+"guest-invoice/:encryptedInvoiceID"}>
          <GuestUserInvoice />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </IonRouterOutlet>
  </IonReactRouter>
);

export default PublicRoutes;
