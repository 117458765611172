import { IonCol, IonIcon, IonRow } from '@ionic/react';
import './Footer.scss'
import { location, mailOpen, phonePortrait } from 'ionicons/icons';
import { useAppContext } from '../services/app-context.service';
import { Link } from 'react-router-dom';
import { appSubDir } from '../configs/app';

const Footer: React.FC = () => {
    const data = useAppContext()

    return <>
        <footer>

            <div className="about">
                <IonRow>
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                        <IonRow>
                            <IonCol sizeXl='6' sizeLg='6' sizeMd='6' size='12'>
                                <h1>{data.settings.app.name}</h1>
                                <p style={{marginTop:0}}>{data.settings.company.title}</p>
                                <p>{data.settings.footer_text}</p>

                            </IonCol>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>Contact Us</h1>
                                <div className='item'><a href={'tel:' + data.settings.company.phone}><IonIcon color='dark' icon={phonePortrait} />{data.settings.company.phone}</a></div>
                                <div className='item'><a href={'mailto:' + data.settings.company.email}><IonIcon color='dark' icon={mailOpen} />{data.settings.company.email}</a></div>
                                <address className='item'>
                                    <IonIcon color='dark' icon={location} />
                                    <div>{data.settings.company.adress_1}<br />{data.settings.company.adress_2}</div>
                                </address>

                            </IonCol>
                            <IonCol sizeXl='3' sizeLg='6' sizeMd='6' size='12'>
                                <h1>Customer Service</h1>
                                <div className='links'>
                                    <Link to={appSubDir + "how-to-buy"}>How To Buy</Link>
                                    <Link to={appSubDir + "refund-policy"}>Return & Refund Policy</Link>
                                    <Link to={appSubDir + "shipping-and-delivery"}>Shipping & Delivery</Link>
                                    <Link to={appSubDir + "prohibited-Items"}>Prohibited Items</Link>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonCol>
                </IonRow>
            </div>

            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1'>
                    <div className='info'>
                        <div className='links'>
                            <a href="#">Terms & Conditions</a><a href="#">Return Policy</a><a href="#">Refund Policy</a><a href="#">Privacy Policy</a>
                        </div>
                        <div className='copyright'>
                            {data.settings.app.name} &copy; {new Date().getFullYear()}, All Rights Reserved.
                        </div>
                    </div>
                </IonCol>
            </IonRow>
        </footer>
    </>
};

export default Footer;
