import { Link } from 'react-router-dom';
import { appSubDir, baseUrl } from '../../configs/app';
import HomeSection, { HomeItem } from '../../types/home.type';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import HomeItemLink from './HomeItemLink';
import { fetchAllAuthors, getAllAuthors } from '../../services/products/all-authors.service';
import { useEffect, useState } from 'react';
import { Author } from '../../types/product.type';
import './AuthorSwiperVerticle.scss'

const slideOpts = {
    autoplay: {
        pauseOnMouseEnter: true,
        delay: 6000
    },
    // loop: true,
    direction: "vertical",
    pagination: false,
    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 0
        },
        // when window width is >= 480px
        768: {
            slidesPerView: 2,
            spaceBetween: 0
        },
        1024: {
            slidesPerView: 2,
            spaceBetween: 0
        },
        1440: {
            slidesPerView: 2,
            spaceBetween: 0
        }
    }
} as any;
type Props = {
    data?: HomeSection
}
function renderItemImg(item: Author) {
    return <Link to={appSubDir + 'authors/' + item.id}>
        <img src={item.image ? baseUrl + `storage/uploads/${item.image}.webp` : baseUrl + `images/default.png`} alt='' />
        {item.name}
    </Link>
}
const AuthorSwiperVerticle: React.FC = () => {
    const [items, setItems] = useState(getAllAuthors())
    useEffect(() => {
        fetchAllAuthors().then((authors) => {
            console.log(authors)
            setItems(authors)
        })
    }, [])
    if (!items) {
        return null
    }
    const midPoint = Math.ceil(items.length / 2)
    const set1 = items.slice(0, midPoint)
    const set2 = items.slice(midPoint + 1)
    return (
        <div className='authors-vertical'>
            <div className='title'>Top Authors</div>
            <div className="authors">
                {items && items.slice(0,9).map((item) => <Link key={item.id} to={appSubDir + 'authors/' + item.id}>
                    <img src={item.image ? baseUrl + `storage/uploads/${item.image}.webp` : baseUrl + `images/default.png`} alt='' />
                    <div>{item.name}</div>
                </Link>)}
            </div>
        </div>
    );
};

export default AuthorSwiperVerticle;
