function onPrint() {
    const printView = document.getElementById('print-view')
    if (printView) {
        let printableContent: string = ""
        document.querySelectorAll('.print-section').forEach((element) => {
            printableContent += element.innerHTML
        })
        printView.innerHTML = printableContent
    }
    window.print()
}
export function clearPrintView() {
    const printView = document.getElementById('print-view')
    if (printView) {
        printView.innerHTML = ""
    }
}
export default onPrint
