import { IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';

const GuestInvoiceLoginError: React.FC = () => {
    return (
        <IonContent scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ textAlign: 'center',minHeight:600 }} className='print-hidden'>
                        <h1 style={{ marginTop: 100, marginBottom: 100 }}>
                            Please, logout to see Guest User Invoice or try another browser
                        </h1>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default GuestInvoiceLoginError;
