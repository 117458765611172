import { useAuthContext, useAuthContextUpdater } from "../../services/auth-context.service";
import { IonButton, IonFooter } from "@ionic/react";

const Footer: React.FC = () => {
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    return <IonFooter>
        {/* {['login', 'registration'].includes(authData.screen) && <div style={{ textAlign: "center" }}>
            <IonButton onClick={() => {
                const role_id = 3
                setAuthData({ ...authData, role_id })
            }} fill={authData.role_id === 3 ? "outline" : "clear"}>
                Customer
            </IonButton>
            <IonButton onClick={() => {
                const role_id = 2
                setAuthData({ ...authData, role_id })
            }} fill={authData.role_id === 2 ? "outline" : "clear"}>
                Reseller
            </IonButton>
        </div>} */}
        <div style={{ textAlign: "center" }}>
            <IonButton onClick={() => {
                const screen = 'login'
                setAuthData({ ...authData, screen })
            }} fill={authData.screen === 'login' ? "outline" : "clear"}>
                Sign In
            </IonButton>
            <IonButton onClick={() => {
                const screen = 'registration'
                setAuthData({ ...authData, screen })
            }} fill={authData.screen === 'registration' ? "outline" : "clear"}>
                Sign Up
            </IonButton>
            <IonButton fill={authData.screen === 'forgot-pass-phone' ? "outline" : "clear"} onClick={() => {
                const screen = 'forgot-pass-phone'
                setAuthData({ ...authData, screen })
            }}>
                Forget Password
            </IonButton>
        </div>
    </IonFooter>
};
export default Footer
