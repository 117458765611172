import './PrimaryMenu.scss'
import WishlistButton from '../Shop/WishlistButton';
import CartModal from '../Shop/CartModal';
import AuthModal from '../Auth/AuthModal';
import SearchBar from './SearchBar';
import { useAppContext } from '../../services/app-context.service';
import UserMenuModal from '../Auth/UserMenuModal';
import QuickLinksModal from './QuickLinksModal';

const MobileMenu: React.FC = () => {
    const { user } = useAppContext()

    return (
        <nav id="bottom-menu">
            <div className='text-center'>
                <WishlistButton />
                <CartModal />
                {user.token ? <UserMenuModal /> : <AuthModal />}
                <QuickLinksModal />
            </div>
            <SearchBar />
        </nav>
    );
};

export default MobileMenu;
