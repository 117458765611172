import { IonBadge, IonButton, IonCol, IonIcon } from "@ionic/react";
import "./CartProduct.scss";
import { CartItem } from "../../types/shop/cart.type";
import { appSubDir, baseUrl } from "../../configs/app";
import { preventOverflow } from "../../functions/string.functions";
import { pencil, pencilOutline, trash } from "ionicons/icons";
import shoppingCartService from "../../services/shop/cart.service";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import CartProductEdit from "./CartProductEdit";
import { Link } from "react-router-dom";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getProductImgUrl, getProductUrl } from "../../functions/product.functions";

type Props = {
    itemIndex: number
    cartItem: CartItem
}
const BDT = new Intl.NumberFormat('en-IN');
const CartProduct: React.FC<Props> = ({ itemIndex, cartItem }) => {
    const data = useAppContext()
    const SetData = useAppContextUpdater()
    function onUpdateCart() {
        data.cart = shoppingCartService.shoppingCart
        SetData({ ...data })
    }
    function onDelete(itemIndex: number) {
        shoppingCartService.removeItemFromCart(itemIndex)
        onUpdateCart()
    }
    return (

        <IonCol size="12">
            <div className='cart-product'>
                <Link to={getProductUrl(cartItem.id,cartItem.slug)} style={{ backgroundImage: `url(${getProductImgUrl(cartItem.image)})` }} className='cover-img' />

                <CartProductEdit className='info' itemIndex={itemIndex} cartItem={cartItem}>
                    <h6>{preventOverflow(cartItem.name, 33)}</h6>
                    <div className="attribute">
                        {!!cartItem.selected_color && <>
                            <p><b>Color : </b>{cartItem.selected_color}</p>
                        </>}
                        {!!cartItem.selected_size && <>
                            <p><b>Size : </b>{cartItem.selected_size}</p>
                        </>}
                    </div>
                    <div className="qty-price">
                        <FontAwesomeIcon icon={faEdit} />
                        <p>{cartItem.quantity} X {BDT.format(cartItem.sale_price)} /=</p>
                    </div>
                </CartProductEdit>
                <div className='price'>
                    <IonBadge color="primary">{BDT.format(cartItem.sale_price * cartItem.quantity)} /=</IonBadge>
                    <IonButton fill="clear" onClick={() => onDelete(itemIndex)}><IonIcon icon={trash} /></IonButton>
                </div>
            </div>
        </IonCol>
    );
}
export default CartProduct
