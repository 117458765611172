import { IonButton, IonCol, IonContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../components/Header/Header';
import { onScroll } from '../services/sticky-menu.service';
import Footer from '../components/Footer';
import { appSubDir } from '../configs/app';

const CommingSoon: React.FC = () => {
    useIonViewDidEnter(() => {
        const tabBar = document.querySelector('ion-tab-bar');
        if (tabBar !== null) {
            tabBar.style.display = 'none';
        }
    })
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div style={{ textAlign: 'center', minHeight: 600 }}>
                        <h1 style={{ marginTop:100, marginBottom:20 }}>Comming Soon</h1>
                        <p style={{ maxWidth:600, padding: "0 20px", margin:'0 auto 50px' }}>Stay tuned! Something amazing is on the horizon. Our team is hard at work crafting an exciting new experience that we can't wait to share with you. Whether you're a loyal customer or a new visitor, get ready for a fresh and innovative offering that will redefine expectations. Keep an eye on this space for updates and sneak peeks as we prepare to unveil what's next. The wait is almost over!</p>
                        <IonButton routerLink={appSubDir} fill='outline'>
                            Go To Home
                        </IonButton>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default CommingSoon;
