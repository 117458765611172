import { apiBaseUrl } from "../../configs/app";
import { lockerHandler } from "../../configs/crypto.config";
import Category, { Subject } from "../../types/category.type";
import CryptoService from "../crypto.services";

const localStorageKey = CryptoService.hash('categories-and-subjects')
export function localCategories() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) : []) as Category[]
}
export function getCategory(categories: Category[], catID: number) {
    return categories.find((item, index) => (catID === item.id))
}
export function getSubject(items: Subject[], subjectID: number) {
    return items.find((item, index) => (subjectID === item.id))
}
export function fetchCategories() {
    const requestOptions = {
        method: 'POST',
        cache: "no-store",
        redirect: 'follow',
        headers: {
            'Accept': 'application/json'
        }
    };
    const url = apiBaseUrl + 'products/categories-and-subjects'
    return fetch(url, requestOptions as RequestInit).then((res) => {
        if (res.status === 200) {
            return res.json() as Promise<Category[]>
        } else {
            throw new Error('Error while fetching categories')
        }
    }).then((res) => {
        window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
        return res
    })
}
