import { apiBaseUrl } from "../../configs/app";
import FbGroup from "../../types/group.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    success: boolean
    totalCount: number
    groupLinks: FbGroup[]
}
const localStorageKey = CryptoService.hash("all-group-links")
export function getLinks() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchLinks() {
    return loginService.privateGetApiCall(apiBaseUrl + "fb-group-links").then((res) => {
        const ApiResponse = res.data as ApiResponse
        if (ApiResponse && ApiResponse.totalCount > 0) {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(ApiResponse)))
            return ApiResponse
        } else {
            return null
        }
    })
}
