import { fetchProducts,getProducts } from '../../services/products/products.service';
import { useEffect, useState } from 'react';
import ProductsHorizontal from '../Products/ProductsHorizontal';
import { appSubDir } from '../../configs/app';

const params = new URLSearchParams("new_arrival=new_arrival&img=web&items_per_page=12")
const NewArrival: React.FC = () => {
    const productResponse = getProducts(params)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : undefined)
    function loadProducts(page: number) {
        const local = getProducts(params)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchProducts(params).then((res) => {
            if (res) {
                setItems(res.data)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts(1)
    }, [])
    return <>
        <ProductsHorizontal title='New Arrival' products={items} delay={4500} routerLink={appSubDir + "new-arrival"} />
    </>
};

export default NewArrival;
