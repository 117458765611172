import React, { createContext, useContext,useEffect,useState } from 'react';
import AppData from '../types/app-data.type';
import loginService from './loginService';
import shoppingCartService from './shop/cart.service';
import { SiteSettings } from '../types/settings.type';
import { apiBaseUrl } from '../configs/app';

export const defaultData = {
	innerWidth:window.innerWidth,
	user:loginService.currentUser,
    cart:shoppingCartService.shoppingCart,
    settings:{
        app: {
            name: "",
            logo: ""
        },
        company: {
            title: "",
            phone: "",
            phone_2: "",
            email: "",
            email_2: "",
            adress_1: "",
            adress_2: ""
        },
        top_header_text: "",
        footer_text: "",
        reseller_enabled: false
    }
} as AppData;

const AppContext = createContext(defaultData);
const AppContextUpdater = createContext((newData:AppData)=>{});

type Props = {
    children:JSX.Element
}
export const AppContextProvider: React.FC<Props> = ({ children }) => {
	const [data,SetData] = useState(defaultData);
    const SetAppData = (newData:AppData)=>{
        SetData(newData)
    }
    const getSettings = ()=>{
        fetch(apiBaseUrl+"site-settings")
        .then((response) => response.json() as Promise<SiteSettings>)
        .then((settings) => SetData(current=>({...current,settings})))
    }
	useEffect(()=>{
        getSettings()
		const onScreenResize = ()=>{
			SetData((currentData)=>{
				const innerWidth = window.innerWidth
				return {...currentData,...{innerWidth}}
			})
		}
		const onFocus = ()=>{
			if(loginService.currentUser.token != loginService.getFromLocalStorage().token){
                window.location.reload()
            }
            const localStorageCart = shoppingCartService.getLocalCartDetails()
			if(localStorageCart && shoppingCartService.shoppingCart.total != localStorageCart.total){
                window.location.reload()
            }
		}
		window.addEventListener("resize", onScreenResize);
		window.addEventListener("focus", onFocus);
		return ()=>{
			window.removeEventListener("resize", onScreenResize);
            window.removeEventListener("focus", onFocus);
		}
	},[])
	return <AppContext.Provider value={data}>
        <AppContextUpdater.Provider value={SetAppData}>
            {children}
        </AppContextUpdater.Provider>
    </AppContext.Provider>
};

export const useAppContext = () => {
	const store = useContext(AppContext);
	if (!store) {
		throw new Error('useAppContext must be used within a AppContextProvider.');
	}
	return store;
};
export const useAppContextUpdater = () => {
	return useContext(AppContextUpdater);
};
