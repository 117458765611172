import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import { useRef, useState } from "react";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { book, closeCircle, storefront } from "ionicons/icons";
import { authModalRef } from "../Auth/AuthModal";
import loginService from "../../services/loginService";
import { apiBaseUrl } from "../../configs/app";

export let shopNameModalRef: React.RefObject<HTMLIonModalElement>
export const ShopNameButton: React.FC = () => {
    return <>
        <IonItem button={true} onClick={() => {
            authModalRef.current?.dismiss()
            shopNameModalRef.current?.present()
        }}>
            <IonIcon slot="start" icon={storefront} />
            <IonText>Shop Details</IonText>
        </IonItem>
    </>
};
export const ShopNameModal: React.FC = () => {
    shopNameModalRef = useRef<HTMLIonModalElement>(null)
    const textareaRef = useRef<HTMLIonTextareaElement>(null)
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const [textareaDisabled, setTextareaDisabled] = useState(false)
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [btnLoading, setBtnLoading] = useState(false)
    const dumy =  "[b]Bbazar[/b]\r\n51/51 A Resourcefull Paltan City,\r\n(level-5,suite-602) Purana Paltan,\r\nDhaka-1000.\r\nPhone: +8801689636569\r\nEmail : bbazarbd@gmail.com"
    function onPresent() {
        setTextareaDisabled(true)
        setBtnDisabled(true)
        loginService.privateGetApiCall(apiBaseUrl+"profile/shop-name").then((res) => {
            if (textareaRef.current) {
                textareaRef.current.value = res.data.details
            }
        }).finally(() => {
            setTextareaDisabled(false)
        })
    }
    function onUpdate() {
        if (textareaRef.current && textareaRef.current.value) {
            setTextareaDisabled(true)
            setBtnDisabled(true)
            setBtnLoading(true)
            loginService.privatePutApiCall(apiBaseUrl+"profile/shop-name",{ details: textareaRef.current.value }).then((res) => {
                appData.user = loginService.currentUser
                setAppData({ ...appData })
            }).finally(() => {
                setTextareaDisabled(false)
                setBtnLoading(false)
            })
        }
    }
    if (appData.user.role_id != 2) {
        return null
    }
    return <IonModal ref={shopNameModalRef} onDidPresent={onPresent}>
        <IonHeader>
            <IonToolbar color="light">
                <IonTitle>My Shop Details</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
            <IonItem>
                <IonTextarea onKeyUp={() => setBtnDisabled(false)} disabled={textareaDisabled} ref={textareaRef} rows={6} placeholder="Enter Your Withdraw Details" />
            </IonItem>
            <h4 className="text-center">Samples</h4>
            <IonItem>
                <IonTextarea readonly={true} rows={7} value={dumy} />
            </IonItem>
        </IonContent>
        <IonFooter>
            <IonButton disabled={btnDisabled} onClick={onUpdate} color="primary" expand="block">
                {btnLoading ? <IonSpinner /> : "Update"}
            </IonButton>

            {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => shopNameModalRef.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton>}
        </IonFooter>
    </IonModal>
};
