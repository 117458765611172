import { IonButton, IonCol, IonContent, IonRow, IonText, useIonViewDidEnter } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { fetchProducts, getProducts } from '../../services/products/flash-sale-products.service';
import { useEffect, useRef, useState } from 'react';
import { appSubDir } from '../../configs/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import CustomCountDown from '../../components/CustomCountDown';
import ProductsGrid from '../../components/Products/ProductsGrid';
import Pagination from '../../components/Pagination';

const FlashSale: React.FC = () => {
    const productResponse = getProducts()
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [loading, setLoadling] = useState(false)
    const [items, setItems] = useState(productResponse ? productResponse.data : null)
    const modal = useRef<HTMLIonModalElement>(null)
    function loadProducts(page: number) {
        const local = getProducts(page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchProducts(page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts(1)
    }, [])
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        <div className='flash-sale mb-50'>
                            <section className='title'>
                                <IonText color="primary" className='f'>Flash</IonText>
                                <FontAwesomeIcon color='#D62447' size="2x" icon={faBolt} />
                                <IonText color="danger" className='s'>Sale</IonText>
                            </section>
                            <CustomCountDown />
                            <hr />
                        </div>
                        <div style={{ marginTop: 30 }}>
                            {items && <ProductsGrid title='Products' products={items} hideHeader={true} />}
                            {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadProducts} buttonCount={lastPage} />}
                        </div>
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default FlashSale;
