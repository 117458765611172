import ShortCode from "../types/shortcode.type";
export function parseShortcodes(str:string){
    var regex = /\[\S+(?:\s+[^="]+="[^"\]]+")+\]/g,
	m, obj, result = [];

	while ((m = regex.exec(str)) !== null) {
		let shortCode = m[0]
	    if (m.index === regex.lastIndex) {
		regex.lastIndex++;
	    }
	    m = shortCode.slice(1, -1).split(/([a-z]*="[a-zA-Z\s0-9]*")/).filter((text)=>text.length>2).map(text=>text.trim())
	    result.push(m.reduce(function(r:any, s){
	        var pair = s.split('=');
			const key = pair[0]
			const value = pair[1].slice(1,-1)
			r[key] = value;
			return r;
	    }, {shortCode,code: m.shift()}));
	}

	return result as ShortCode[];
};

export function splitInHalf(str:string){
    const spaceCount = str.split("").filter((char) => char === " ").length
    const half = (spaceCount === 1) ? str.split("").findIndex((char) => char === " ") : Math.floor(str.length / 2);
    return [str.slice(0, half) , str.slice(half, str.length)];
}

export function preventOverflow(str:string,lenth:number){
    if(str.length>lenth){
        return str.slice(0,lenth) + "..."
    }else{
        return str
    }
}
