import {
    IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonList, IonModal,
    IonText,
    IonTitle, IonToolbar
} from "@ionic/react";
import { closeCircle, list, listCircle, logoFacebook, logoYoutube, phonePortrait, shapes, shapesOutline } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { appSubDir, baseUrl } from "../../configs/app";
import { fetchCategories, localCategories } from "../../services/products/all-category.service";
import { quickLinksModalRef } from "./QuickLinksModal";

export let allCategoryModalRef: React.RefObject<HTMLIonModalElement>
const AllCategoryModal: React.FC = () => {
    allCategoryModalRef = useRef<HTMLIonModalElement>(null)
    const [categories, setCategories] = useState(localCategories())
    useEffect(() => {
        if (categories.length < 1) {
            fetchCategories().then((items) => {
                setCategories(items)
            })
        }
    }, [])
    return <>
        <IonItem onClick={() => allCategoryModalRef.current?.present()}>
            <IonIcon slot="start" icon={listCircle} />
            <IonText>All Categories</IonText>
        </IonItem>
        <IonModal ref={allCategoryModalRef} id='cart-modal'>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>All Categories</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
                <IonList>
                    {categories.map((item, index) => {
                        return <IonItem key={index} routerLink={appSubDir + "categories/" + item.id} onClick={()=>{
                            allCategoryModalRef.current?.dismiss().then(()=>{
                                quickLinksModalRef.current?.dismiss()
                            })
                        }}>
                            {item.title}
                        </IonItem>
                    })}
                </IonList>
            </IonContent>
            <IonFooter>
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => allCategoryModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> Close
                </IonButton>}
            </IonFooter>
        </IonModal>
    </>
};
export default AllCategoryModal
