import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { closeCircle, person } from "ionicons/icons";
import { useRef } from "react";
import { useAppContext } from "../../services/app-context.service";
import UserMenu from "./UserMenu";
import { baseUrl } from "../../configs/app";
import { WithdrawDetailsModal } from "../Account/WithdrawDetailsModal";
import { EditProfileModal } from "../Account/EditProfile";
import { ShopNameModal } from "../Account/ShopNameModal";

export let userMenuModalRef: React.RefObject<HTMLIonModalElement>
const UserMenuModal: React.FC = () => {
    userMenuModalRef = useRef<HTMLIonModalElement>(null)
    const { user } = useAppContext()
    function onClick() {
        userMenuModalRef.current?.present()
    }
    return <>
        <IonButton fill='clear' onClick={onClick} id="btn-auth-modal" className={user.token && user.photo ? "no-padding" : undefined}>
            {user.photo ?
                <img src={baseUrl + user.photo} alt="" className="cover" /> :
                <IonIcon size='large' icon={person} />}
        </IonButton>
        <IonModal ref={userMenuModalRef}>
            <IonHeader>
                <IonToolbar color="light">
                    <IonTitle>My Menu</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent color="light">
                <UserMenu />
            </IonContent>
            <IonFooter>
                {window.screen.width <= 600 && <IonButton fill='clear' expand="block" onClick={() => userMenuModalRef.current?.dismiss()}>
                    <IonIcon slot="start" icon={closeCircle} /> Close
                </IonButton>}
            </IonFooter>
        </IonModal>
        <WithdrawDetailsModal />
        <EditProfileModal />
        <ShopNameModal />
    </>
};
export default UserMenuModal
