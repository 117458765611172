import { useAppContext } from '../../services/app-context.service';
const BDT = new Intl.NumberFormat('en-IN');
const CartTotal: React.FC = () => {
    const { cart, user } = useAppContext()
    if (cart.itemCount < 1) {
        return null
    }
    return <div id="cart-total">
        {user.role_id == 2 && cart.commissionSubTotal > 0 && <div>
            Commission : <span>{BDT.format(cart.totalResellerCommission)} /=</span>
        </div>}
        <div>
            {cart.itemCount} Products
        </div>
        <div>
            Total : <span>{BDT.format(cart.total)} /=</span>
        </div>
    </div>
};

export default CartTotal;
