import { IonButton, IonCol, IonRow, IonText } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import ProductCard from './ProductCard';
import { splitInHalf } from '../../functions/string.functions';
import { appSubDir } from '../../configs/app';
type Props = {
    title: string
    hideHeader?: boolean
    splitTitle?: boolean
    products?: SimpleProduct[]
    routerLink?: string
}
const ProductsGrid: React.FC<Props> = ({ title, products, hideHeader, routerLink, splitTitle }) => {
    if (!products) {
        return null
    }
    const titleParts = splitInHalf(title)
    return (
        <section className='h-products mb-50'>
            {!hideHeader && <IonRow class='head'>
                <IonCol>
                    <div className='title'>
                        {splitTitle ? <>
                            <IonText color="dark" className='f'>{titleParts[0]}</IonText>
                            <IonText className='s'> {titleParts[1]}</IonText>
                        </> : <>
                            <IonText color="dark" className='f'>{title}</IonText>
                        </>}
                    </div>
                </IonCol>
                <IonCol className='text-end'>
                    {routerLink ?
                        <IonButton routerLink={routerLink} color='secondary'>View All</IonButton> :
                        <IonButton routerLink={appSubDir + "shop"} color='secondary' >See More</IonButton>}
                </IonCol>
            </IonRow>}
            {products && <IonRow>
                {products.map((item, index) => <IonCol sizeXs='6' sizeSm='4' sizeMd='2' sizeLg='2' sizeXl='2' key={index}>
                    <ProductCard product={item} />
                </IonCol>)}
            </IonRow>}
        </section>
    );
};

export default ProductsGrid;
