import { apiBaseUrl } from "../../configs/app";
import Transaction from "../../types/transaction.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    current_page: number
    data: Transaction[]
    first_page_url: string
    last_page_url: string
    next_page_url: string
    prev_page_url: string | null
    path: string
    from: number
    last_page: number
    per_page: number
    to: number
    total: number
}
function getKey(pageNo: number = 1) {
    return CryptoService.hash("all-transactions-" + pageNo)
}
export function getAllTransactions(pageNo: number = 1) {
    const localStorageKey = getKey(pageNo)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchAllTransactions(pageNo: number = 1) {
    const localStorageKey = getKey(pageNo)
    return loginService.privateGetApiCall(apiBaseUrl + "transactions", { page: pageNo }).then((res) => {
        const ApiResponse = res.data as ApiResponse
        if (ApiResponse && ApiResponse.total > 0) {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(ApiResponse)))
            return ApiResponse
        } else {
            return null
        }
    })
}
