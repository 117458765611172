import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import ProductsVerticalBox from './ProductsVerticalBox';
import { fetchCategoryProducts, getCategoryProducts } from '../../services/products/category-products.service';
import ProductsGrid from './ProductsGrid';
const slideOpts = {
    autoplay: {
        delay: 4000
    },
    pagination: false,
    direction: "vertical",
    loop: true,
    slidesPerView: 4,
    spaceBetween: 10
};
type Props = {
    catId: number
    excludedProductId?: number
    title: string
    grid?: boolean
}
const CategoryProducts: React.FC<Props> = ({ catId, title, excludedProductId, grid }) => {
    const [items, setItems] = useState(getCategoryProducts(catId)?.data)
    function fetchData() {
        fetchCategoryProducts(catId)
            .then(res => {
                if (res) {
                    if(excludedProductId){
                        setItems(res.data.filter((items2, index) => items2.id != excludedProductId))
                    }else{
                        setItems(res.data)
                    }
                }
            })
    }
    useEffect(() => {
        fetchData()
    }, [])
    if (!items || items.length < 1) {
        return null
    }
    if (grid) {
        return (
            <ProductsGrid title={title} products={items} routerLink={appSubDir + "categories/" + catId} />
        );
    } else {
        return (
            <ProductsVerticalBox title={title} products={items} slideOpts={slideOpts} routerLink={appSubDir + "categories/" + catId} />
        );
    }
};

export default CategoryProducts;
