import { apiBaseUrl } from "../../configs/app";
import { Author } from "../../types/product.type";
import CryptoService from "../crypto.services";
const localStorageKey = CryptoService.hash("all-authors")
export function getAllAuthors() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Author[] : null)
}
export function getAuthor(id:number) {
    const localData = getAllAuthors()
    const result =  (localData ? localData.find((item)=>item.id===id) : null)
    return result ? result : null
}
export function fetchAllAuthors() {
    const url = apiBaseUrl + 'authors'
    return fetch(url).then((res) => res.json())
        .then((items: Author[]) => {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(items)))
            return items
        })
}
