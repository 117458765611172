import { apiBaseUrl } from "../../configs/app";
import { lockerHandler } from "../../configs/crypto.config";
import Category, { Subject } from "../../types/category.type";
import CryptoService from "../crypto.services";

function getKey(id: number) {
    return CryptoService.hash('category-' + id)
}
export function localCategory(id: number) {
    const localData = window.localStorage.getItem(getKey(id))
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Category : null)
}
export function fetchCategory(id: number) {
    const requestOptions = {
        method: 'POST',
        cache: "no-store",
        redirect: 'follow',
        headers: {
            'Accept': 'application/json'
        }
    };
    const url = apiBaseUrl + 'products/categories/' + id
    return fetch(url, requestOptions as RequestInit).then((res) => {
        if (res.status === 200) {
            return res.json() as Promise<Category>
        } else {
            throw new Error('Error while fetching category')
        }
    }).then((res) => {
        window.localStorage.setItem(getKey(id), CryptoService.encrypt(JSON.stringify(res)))
        return res
    })
}
