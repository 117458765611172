import { IonButton, IonCol, IonContent, IonRow } from '@ionic/react';
import Header from '../../components/Header/Header';
import { onScroll } from '../../services/sticky-menu.service';
import Footer from '../../components/Footer';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import Pagination from '../../components/Pagination';
import { useParams } from 'react-router';
import { getAllTransactions, fetchAllTransactions } from '../../services/account/transaction-detail.service';
import { Link } from 'react-router-dom';
import HtmlParser from '../../components/HtmlParser';

const BDT = new Intl.NumberFormat('en-IN');
const TransactionsDetailsPage: React.FC = () => {
    const { date } = useParams() as any
    const [loading, setLoadling] = useState(false)
    const response = getAllTransactions(date)
    const [page, setPage] = useState(1)
    const [lastPage, setLastPage] = useState(0)
    const [items, setItems] = useState(response ? response.data : null)
    useEffect(() => {
        loadData(1)
    }, [])
    function loadData(page: number) {
        const local = getAllTransactions(date, page)
        if (local) {
            setItems(local.data)
        } else {
            setLoadling(true)
        }
        fetchAllTransactions(date, page).then((res) => {
            if (res) {
                setItems(res.data)
                setLastPage(res.last_page)
                setPage(res.current_page)
            }
        }).finally(() => setLoadling(false))
    }
    function extractInvoiceId(str: string) {
        return str.replace("Order #", "")
    }
    return (
        <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
            <Header />
            <IonRow>
                <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding bg-content'>
                    <div className='page-container'>
                        {!items && !loading && <div className='text-center'>
                            <h1 style={{ marginTop: 100, marginBottom: 100 }}>No Transation Found</h1>
                        </div>}
                        {items && <div className='page-container'>
                            <div className='title-bar' style={{ marginTop: 60, marginBottom: 50 }}>
                                <h1>Transation Details (Date: {date})</h1>
                                <IonButton routerLink={appSubDir+"transactions"} fill='outline'>
                                    Date Wise Transaction History
                                </IonButton>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                        <tr>
                                            <th className='mobile-invisible'>Date</th>
                                            <th>Sub Total</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items.map((item, index) => {
                                            const hasInvoiceId = item.description.match(/Order #[0-9]*/g)
                                            return <tr style={{ cursor: "pointer" }} key={index}>
                                                <td className='text-center'>{item.t_date}</td>
                                                <td>
                                                    {hasInvoiceId ? <>
                                                        Order <Link to={appSubDir + "invoices/" + extractInvoiceId(hasInvoiceId[0])}>#{extractInvoiceId(hasInvoiceId[0])}</Link>
                                                        {item.description.replace(/Order #[0-9]*/g, "")}
                                                    </> : <> {<HtmlParser html={item.description.replaceAll("\n",'<br/>')}/>}</>}
                                                </td>
                                                <td className='text-end'>{BDT.format(item.amount)}/=</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginTop: 30 }}>
                                {lastPage > 1 && <Pagination active={page} disabled={loading} onClick={loadData} buttonCount={lastPage} />}
                            </div>
                        </div>}
                    </div>
                </IonCol>
            </IonRow>
            <Footer />
        </IonContent>
    );
};

export default TransactionsDetailsPage;
