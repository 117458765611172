import { IonButton, IonButtons, IonCard, IonCardContent, IonIcon, IonText, useIonToast } from '@ionic/react';
import { baseUrl } from '../../configs/app';
import { SimpleProduct } from '../../types/product.type';
import './ProductCard.scss'
import QuickView from './QuickView';
import { cartOutline, eyeOutline, navigateOutline, optionsOutline, sendOutline } from 'ionicons/icons';
import ProgressiveImg from '../ProgressiveImg';
import { getProductImgUrl, getProductUrl } from '../../functions/product.functions';
import { Link } from 'react-router-dom';
import { CartItem } from '../../types/shop/cart.type';
import shoppingCartService from '../../services/shop/cart.service';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import { fetchProduct } from '../../services/products/product.service';
import { cartModalRef } from '../Shop/CartModal';
type Props = {
    product: SimpleProduct
}
const ProductCard: React.FC<Props> = ({ product }) => {
    const data = useAppContext()
    const SetData = useAppContextUpdater()
    const [presentToast] = useIonToast();
    const productUrl = getProductUrl(product.id, product.name)
    function showMessage(mesage: string, color: "success" | "error" | "tertiary") {
        presentToast({
            message: mesage,
            duration: 3000,
            cssClass: 'custom-toast',
            animated: true,
            color,
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ],
        })
    }
    function onAddToCart() {
        if (product.color_status === '0' && product.size_status === '0') {
            fetchProduct(product.id).then((item) => {
                if (item) {
                    const cartItem = { ...item } as CartItem
                    const selectedQuantity = 1
                    shoppingCartService.addToCart(cartItem, selectedQuantity)
                    data.cart = shoppingCartService.shoppingCart
                    presentToast({
                        message: selectedQuantity + " item added to the cart.",
                        duration: 3000,
                        cssClass: 'custom-toast',
                        animated: true,
                        color: "success",
                        buttons: [
                            {
                                text: "View Cart",
                                handler: () => {
                                    cartModalRef.current?.present()
                                }
                            },
                            {
                                text: 'Dismiss',
                                role: 'cancel'
                            }
                        ],
                    })
                    SetData({ ...data })
                }
            })
        }
    }
    function onSelctOption() {

        presentToast({
            message: "অপশন সিলেক্ট করুন",
            duration: 1000,
            cssClass: 'custom-toast',
            animated: true,
            color: "tertiary",
            buttons: [
                {
                    text: 'Dismiss',
                    role: 'cancel'
                }
            ],
        })
    }
    return <div className='product'>
        <div className="head">
            <Link to={productUrl}>
                <ProgressiveImg src={getProductImgUrl(product.image)} alt="" />
            </Link>
            <IonButtons>
                {(product.color_status === "0" && product.size_status === '0') ?
                    <IonButton title='Add To Cart' onClick={onAddToCart} color='primary'><IonIcon icon={cartOutline} /></IonButton> :
                    <QuickView productId={product.id} title={product.name} >
                        <IonButton title='Select Option' onClick={onSelctOption} color='primary'><IonIcon icon={optionsOutline} /></IonButton>
                    </QuickView>
                }
                <QuickView productId={product.id} title={product.name} >
                    <IonButton title='Quick View' color='primary'><IonIcon icon={eyeOutline} /></IonButton>
                </QuickView>
                <IonButton routerLink={productUrl} title='Product Details' color='primary'><IonIcon icon={navigateOutline} /></IonButton>
            </IonButtons>
        </div>

        <h2>{product.name}</h2>
        <div className='pricing'>
            <IonText className='regular'>৳ {product.regular_price}</IonText>
            <IonText className='sale' color='primary'>৳ {product.sale_price}</IonText>
        </div>
    </div>
};

export default ProductCard;
