import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonNote, IonSpinner, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useRef, useState } from "react";
import "./LoginForm.scss";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import loginService from "../../services/loginService";
import { cloudUpload, key, mail } from "ionicons/icons";
import { authModalRef } from "./AuthModal";
import { useAuthContext, useAuthContextUpdater } from "../../services/auth-context.service";
import Footer from "./Footer";
import { AxiosError } from "axios";
const LoginForm: React.FC = () => {
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const authData = useAuthContext()
    const setAuthData = useAuthContextUpdater()
    const email = useRef<HTMLIonInputElement>(null)
    const password = useRef<HTMLIonInputElement>(null)
    const [emailError, setEmailError] = useState("")
    const [passError, setPassError] = useState("")
    const [buttonLoading, SetButtonLoading] = useState(false)
    const [disabled, SetDisabled] = useState(true)
    const [presentToast] = useIonToast();
    const onSubmit = () => {
        // history.replace('/home')
        if (!isValid()) {
            return null
        }
        const emailStr = (email.current && email.current.value) ? email.current.value.toString() : ""
        const passwordStr = (password.current && password.current.value) ? password.current.value.toString() : ""

        SetButtonLoading(true)
        SetDisabled(true)
        loginService.doLogin(emailStr, passwordStr, authData.role_id).then(() => {
            appData.user = loginService.currentUser
            setAppData({ ...appData })
            presentToast({
                message: "Login Was Sccessfull",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            authModalRef.current?.dismiss()
        }).catch((err: AxiosError) => {
            const messages = [];
            const errRes = err.response?.data as any
            if (errRes && errRes.message) {
                messages.push(errRes && errRes.message)
            } else {
                messages.push("Something Went Wrong")
            }
            presentToast({
                message: messages.join(","),
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            SetButtonLoading(false)
            SetDisabled(!isValid())
        })
    }
    const isValid = () => {
        return !!email.current?.value && !!password.current?.value
    }
    const onEmailChange = () => {
        if (email.current?.value) {
            setEmailError("")
        } else {
            setEmailError("Cannot be blank")
        }
        SetDisabled(!isValid())
    }
    const onPassChange = () => {
        if (password.current?.value) {
            setPassError("")
        } else {
            setPassError("Cannot be blank")
        }
        SetDisabled(!isValid())
    }
    return <>
        <IonHeader>
            <IonToolbar color="light">
                <IonTitle>{authData.role_id == 2 ? "Reseller" : "Customer"} Sign In</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent color="light" className='ion-padding'>
            <div style={{ maxWidth: 300, margin: "100px auto 0" }}>
                <IonItem className={`${!!emailError && 'ion-invalid'}`}>
                    <IonLabel position="floating"><IonIcon icon={mail} />Email Or Phone Number</IonLabel>
                    <IonInput clearInput={true} ref={email} onIonChange={onEmailChange} />
                    {!!emailError && <IonNote slot='error'>{emailError}</IonNote>}
                </IonItem>
                <IonItem className={`${!!passError && 'ion-invalid'}`}>
                    <IonLabel position="floating"><IonIcon icon={key} />Password</IonLabel>
                    <IonInput type='password' clearInput={true} ref={password} onIonChange={onPassChange} />
                    {!!passError && <IonNote slot='error'>{passError}</IonNote>}
                </IonItem>
                <IonButton disabled={disabled} onClick={onSubmit} expand='block' color={authData.role_id == 2 ? "primary" : "success"}>
                    {!buttonLoading && <IonIcon icon={cloudUpload} slot="start" />}
                    {buttonLoading && <IonSpinner slot="start" name="lines-sharp"></IonSpinner>}
                    {buttonLoading ? "Verifing" : "Login"}
                </IonButton>
            </div>
        </IonContent>
        <Footer />
    </>
};
export default LoginForm
