import { IonButton } from '@ionic/react';
import { SimpleProduct } from '../../types/product.type';
import './ProductsVerticalBox.scss'
import ProductListItem from './ProductListItem';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { appSubDir } from '../../configs/app';
type Props = {
    title: string
    routerLink?: string
    products: SimpleProduct[]
    slideOpts: any
}
const ProductsVerticalBox: React.FC<Props> = ({ title, products, slideOpts, routerLink }) => {
    return (
        <section className='v-products-box mb-50'>
            <div className='title'>{title}</div>
            {products && <Swiper {...slideOpts} modules={[Autoplay]}>
                {products.map((item, index) => <SwiperSlide key={index}>
                    <ProductListItem product={item} />
                </SwiperSlide>)}
            </Swiper>}
            <div className='btn-container'>
                {routerLink ? <IonButton routerLink={routerLink} fill='outline' color="primary">View All</IonButton> : <IonButton routerLink={appSubDir+"shop"} fill='outline' color="primary">See More</IonButton>}
            </div>
        </section>
    );
};

export default ProductsVerticalBox;
