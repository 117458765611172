import { IonButton, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonModal, IonNote, IonSpinner, IonText, IonTextarea, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import { useRef, useState } from "react";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { book, closeCircle, pencil } from "ionicons/icons";
import { authModalRef } from "../Auth/AuthModal";
import loginService from "../../services/loginService";
import { Formik, FormikHelpers } from "formik";

export type FormData = {
    name: string
    phone: string
    email: string
    imageFile: File
}
export let editProfileModalRef: React.RefObject<HTMLIonModalElement>
export const EditProfileButton: React.FC = () => {
    return <>
        <IonButton size="small" expand="block" fill="clear" onClick={() => {
            authModalRef.current?.dismiss()
            editProfileModalRef.current?.present()
        }}>
            <IonIcon slot="start" icon={pencil} />
            <IonText>Edit Profile</IonText>
        </IonButton>
    </>
};
export const EditProfileModal: React.FC = () => {
    editProfileModalRef = useRef<HTMLIonModalElement>(null)
    const textareaRef = useRef<HTMLIonTextareaElement>(null)
    const appData = useAppContext()
    const setAppData = useAppContextUpdater()
    const [presentToast] = useIonToast();
    function onPresent() {
        if (textareaRef.current) {
            textareaRef.current.value = loginService.currentUser.bkash_rocket
        }
        loginService.load().then((res) => {
            appData.user = loginService.currentUser
            setAppData({ ...appData })
        }).finally(() => {
            if (textareaRef.current) {
                textareaRef.current.value = loginService.currentUser.bkash_rocket
            }
        })
    }
    function validator(values: FormData) {
        const errors = {} as FormData;
        if (!values.name) {
            errors.name = 'Required';
        } else if (
            values.name.includes("@")
        ) {
            errors.name = 'Invalid Name address';
        }

        if (!values.phone) {
            errors.phone = 'Required';
        } else if (
            values.phone.length != 11
        ) {
            errors.phone = '11 Digit Required';
        } else if (
            !values.phone.match(/^(01){1}[3-9]{1}\d{8}/g)
        ) {
            errors.phone = 'Invalid Phone Number';
        }

        return errors;
    }
    function onSubmit(values: FormData, formikHelpers: FormikHelpers<FormData>) {
        const { setSubmitting } = formikHelpers
        setSubmitting(true);
        loginService.editDetails(values).then(() => {
            appData.user = loginService.currentUser
            setAppData({ ...appData })
            presentToast({
                message: 'Success',
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).catch(err => {
            presentToast({
                message: err.response.data.message ? err.response.data.message : "Something Went Wrong",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "danger",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
        }).finally(() => {
            setSubmitting(false);
        })
    }
    return <IonModal ref={editProfileModalRef} onDidPresent={onPresent}>
        <IonHeader>
            <IonToolbar color="light">
                <IonTitle>Edit Profile</IonTitle>
            </IonToolbar>
        </IonHeader>
        <IonContent color="light" className='ion-padding' style={{ overflow: "auto" }}>
            <Formik
                initialValues={{
                    name: appData.user.name,
                    phone: appData.user.phone,
                    email: appData.user.email
                } as FormData}
                validate={validator}
                onSubmit={onSubmit}>
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit} style={{ marginTop: 10 }}>
                        <IonItem className={errors.name && touched.name ? 'ion-invalid' : undefined}>
                            <IonLabel position="floating">Name</IonLabel>
                            <IonInput name='name'
                                onIonChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name} />
                            {errors.name && touched.name && <IonNote slot='error'>{errors.name}</IonNote>}
                        </IonItem>
                        <IonItem className={errors.phone && touched.phone ? 'ion-invalid' : undefined}>
                            <IonLabel position="floating">Phone</IonLabel>
                            <IonInput name='phone' placeholder='01XXXXXXXXX (BD Mobile Number Only)' min={1000000000} max={1999999999}
                                onIonChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone} />
                            {errors.phone && touched.phone && <IonNote slot='error'>{errors.phone}</IonNote>}
                        </IonItem>
                        <IonItem className={errors.email && touched.email ? 'ion-invalid' : undefined}>
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput name='email' min={1000000000} max={1999999999}
                                onIonChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email} />
                            {errors.email && touched.email && <IonNote slot='error'>{errors.email}</IonNote>}
                        </IonItem>
                        <IonButton disabled={isSubmitting} type="submit" color="primary" expand="block">
                            {isSubmitting ? <IonSpinner /> : "Update"}
                        </IonButton>
                    </form>
                )}
            </Formik>
        </IonContent>
        {window.screen.width <= 600 && <IonFooter>
            <IonButton fill='clear' expand="block" onClick={() => editProfileModalRef.current?.dismiss()}>
                <IonIcon slot="start" icon={closeCircle} /> Close
            </IonButton>
        </IonFooter>}
    </IonModal>
};
