import { useAppContext } from '../../services/app-context.service';
import "./CartSubTotal.scss";
const BDT = new Intl.NumberFormat('en-IN');
const CartSubTotal: React.FC = () => {
    const { cart, user } = useAppContext()
    if (cart.itemCount < 1) {
        return null
    }
    return <div id="cart-total">
        {user.role_id == 2 && cart.commissionSubTotal > 0 && <div>
            Commission : <span>{BDT.format(cart.commissionSubTotal)} /=</span>
        </div>}
        <div>
            {cart.itemCount} Product{(cart.itemCount != 1 ? "s" : "")}
        </div>
        <div>
            Sub Total : <span>{BDT.format(cart.subTotal)} /=</span>
        </div>
    </div>
};

export default CartSubTotal;
