import * as React from "react"
const AllCategoriesIcon = (props:any) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <circle cx={16} cy={16} r={16} fill="#fff" fillRule="evenodd" />
    <path
      fill="#eb2227"
      fillRule="evenodd"
      d="M5.213 4.95h8.533v8.533H5.213z"
    />
    <circle cx={9.48} cy={22.784} r={4.267} fill="#ffd400" fillRule="evenodd" />
    <path
      fill="#29abe2"
      fillRule="evenodd"
      d="m26.783 13.483-9.836-.03 4.944-8.503z"
    />
    <path
      fill="#51b748"
      fillRule="evenodd"
      d="m24.86 27.05-6.083-.049-1.834-5.801 4.951-3.536 4.893 3.615z"
    />
  </svg>
)
export default AllCategoriesIcon
