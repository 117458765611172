import { apiBaseUrl } from "../../configs/app";
import RankItem from "../../types/reseller/rank.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";
type ApiResponse = {
    total_order: string
    total_withdrawn: string
    current_balance: string
    last_month_income: string
    this_month_income: string
    reseller_rank: string
    pending_order: string
    delivered_order: string
    complete_order: string
}
const localStorageKey = CryptoService.hash("reseller-dashboard")
export function getDashboardData() {
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as ApiResponse : null)
}
export function fetchDashboardData() {
    return loginService.privateGetApiCall(apiBaseUrl + "reseller/dashboard").then((res) => {
        const ApiResponse = res.data as ApiResponse
        if (ApiResponse) {
            window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(ApiResponse)))
            return ApiResponse
        } else {
            return null
        }
    })
}
