import { IonCard, IonCardContent, IonCol, IonContent, IonRow } from '@ionic/react';
import FlashSale from '../components/Home/FlashSale';
import CategorySwiper from '../components/Home/CategorySwiper';
import SlidesMini from '../components/Home/SlidesMini';
import Header from '../components/Header/Header';
import AllCategories from '../components/Home/AllCategories';
import AuthorSwiperVerticle from '../components/Home/AuthorSwiperVerticle';
import Footer from '../components/Footer';
import { onScroll } from '../services/sticky-menu.service';
import Slides from '../components/Home/Slides';
import NewArrival from '../components/Home/NewArrival';
import AllProducts from '../components/Home/AllProducts';
import './Home.scss';
import CategoryGrid from '../components/Home/CategoryGrid';
import { appSubDir } from '../configs/app';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
    return (
        <>
            <IonContent fullscreen scrollEvents={true} onIonScroll={onScroll}>
                <Header />
                <IonRow id="home-page">
                    <IonCol sizeXl='10' offsetXl='1' sizeLg='10' offsetLg='1' className='no-padding'>
                        <IonCard>
                            <IonCardContent>
                                {window.screen.width > 768 && <IonRow className='home-slider'>
                                    <IonCol sizeLg='3'>
                                        <AllCategories />
                                    </IonCol>
                                    <IonCol sizeLg='6'>
                                        <Slides />
                                    </IonCol>
                                    <IonCol sizeLg='3'>
                                        <AuthorSwiperVerticle />
                                    </IonCol>
                                </IonRow>}
                                {(768 >= window.screen.width && window.screen.width > 600) && <IonRow className='home-slider'>
                                    <IonCol sizeMd='5'>
                                        <AllCategories />
                                    </IonCol>
                                    <IonCol sizeMd='7'>
                                        <SlidesMini />
                                    </IonCol>
                                </IonRow>}
                                {window.screen.width <= 600 && <>
                                    <Slides />
                                    <AuthorSwiperVerticle />
                                </>}
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <FlashSale />
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size='12' sizeLg='4'>
                                        <Link to={appSubDir + "categories/11"}>
                                            <img width={"100%"} src={appSubDir + "assets/img/home-banner-1.webp"} alt="" />
                                        </Link>
                                    </IonCol>
                                    <IonCol size='12' sizeLg='4'>
                                        <Link to={appSubDir + "printing-service"}>
                                            <img width={"100%"} src={appSubDir + "assets/img/home-banner-2.webp"} alt="" />
                                        </Link>
                                    </IonCol>
                                    <IonCol size='12' sizeLg='4'>
                                        <Link to={appSubDir + "categories/12"}>
                                            <img width={"100%"} src={appSubDir + "assets/img/home-banner-3.webp"} alt="" />
                                        </Link>
                                    </IonCol>

                                </IonRow>
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <NewArrival />
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <CategoryGrid id={1} />
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <CategoryGrid id={12} />
                            </IonCardContent>
                        </IonCard>
                        <IonCard>
                            <IonCardContent>
                                <AllProducts />
                            </IonCardContent>
                        </IonCard>
                    </IonCol>
                </IonRow>
                <Footer />
            </IonContent>
        </>
    );
};

export default Home;
