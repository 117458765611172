import { fetchProducts, getProducts } from '../../services/products/products.service';
import { useEffect, useState } from 'react';
import { appSubDir } from '../../configs/app';
import ProductsGrid from '../Products/ProductsGrid';
import { fetchCategory, localCategory } from '../../services/products/category.service';

type Props = {
    id: number
}
const CategoryGrid: React.FC<Props> = ({ id }) => {
    const params = new URLSearchParams(`category_id=${id}&img=web&items_per_page=12`)
    const productResponse = getProducts(params)
    const [loading, setLoadling] = useState(false)
    const [cat,setCat] = useState(localCategory(id))
    const [items, setItems] = useState(productResponse ? productResponse.data : undefined)
    function loadProducts() {
        fetchProducts(params).then((res) => {
            if (res) {
                setItems(res.data)
            }
        }).finally(() => setLoadling(false))
    }
    useEffect(() => {
        loadProducts()
        fetchCategory(id).then((res)=>setCat(res))
    }, [])
    function getCatLink(id: Number) {
        return appSubDir + "categories/" + id
    }
    return <>
        <ProductsGrid splitTitle={true} title={cat ? cat.title : "Loading"} products={items} routerLink={getCatLink(id)} />
    </>
};

export default CategoryGrid;
