import { IonText } from '@ionic/react';
import { useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

type Props = {
    action: string;
    onVerify: (token: string|null) => void;
}

const GoogleReCaptcha: React.FC<Props> = ({ action,onVerify }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(() => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        executeRecaptcha(action).then((token)=>{
            onVerify(token)
        }).catch((error)=>{
            console.error("Recaptcha Token Verify Failed")
            console.error(error)
        })
        // Do whatever you want with the token
    }, [executeRecaptcha]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        setTimeout(() => {
            handleReCaptchaVerify();
        }, 100);
    }, [handleReCaptchaVerify]);

    return <IonText>Verifying recaptcha</IonText>;
};

export default GoogleReCaptcha;
