import { IonButton, IonButtons, IonIcon, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption, IonText, useIonToast } from '@ionic/react';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import shoppingCartService from '../../services/shop/cart.service';
import { CartItem } from '../../types/shop/cart.type';
import { useAppContext, useAppContextUpdater } from '../../services/app-context.service';
import Product from '../../types/product.type';
import { cartModalRef } from '../Shop/CartModal';
import { logoFacebook, logoWhatsapp } from 'ionicons/icons';
import ProductWishlistButton from './ProductWishlistButton';
import './QuickViewAddToCart.scss'
type Props = {
    item: Product
    modalRef?: React.RefObject<HTMLIonModalElement>
}
const QuickViewAddToCart: React.FC<Props> = ({ item, modalRef }) => {
    const data = useAppContext()
    const [presentToast] = useIonToast();
    const SetData = useAppContextUpdater()
    const input = useRef<HTMLIonInputElement>(null)
    const colorSelect = useRef<HTMLIonSelectElement>(null)
    const sizeSelect = useRef<HTMLIonSelectElement>(null)
    function getInputValue() {
        if (input.current && input.current.value) {
            if (typeof input.current.value == "string") {
                return parseInt(input.current.value) || 0
            } else {
                return input.current.value
            }
        }
        return 0
    }
    function onChange() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            if (selectedQuantity < 1) {
                input.current.value = 1
            }
        }
    }
    function onIncreament() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            input.current.value = selectedQuantity + 1
        }
    }
    function onDecreament() {
        if (input.current && input.current.value) {
            const selectedQuantity = getInputValue()
            if (selectedQuantity > 1) {
                input.current.value = selectedQuantity - 1
            }
        }
    }
    function onAddToCart() {
        const selectedQuantity = getInputValue()
        if (selectedQuantity > 0) {
            const cartItem = { ...item } as CartItem
            if (item.color_status === '1') {
                cartItem.selected_color = colorSelect.current?.value
                if (!cartItem.selected_color) {
                    presentToast({
                        message: "Select A Color First",
                        duration: 3000,
                        cssClass: 'custom-toast',
                        animated: true,
                        color: "danger",
                        buttons: [
                            {
                                text: 'Dismiss',
                                role: 'cancel'
                            }
                        ],
                    })
                    return null
                }
            }
            if (item.size_status === '1') {
                cartItem.selected_size = sizeSelect.current?.value
                if (!cartItem.selected_size) {
                    presentToast({
                        message: "Select A Size First",
                        duration: 3000,
                        cssClass: 'custom-toast',
                        animated: true,
                        color: "danger",
                        buttons: [
                            {
                                text: 'Dismiss',
                                role: 'cancel'
                            }
                        ],
                    })
                    return null
                }
            }
            shoppingCartService.addToCart(cartItem, selectedQuantity)
            data.cart = shoppingCartService.shoppingCart
            presentToast({
                message: selectedQuantity + (selectedQuantity > 1 ? " items" : " item") + " added to the card.",
                duration: 3000,
                cssClass: 'custom-toast',
                animated: true,
                color: "success",
                buttons: [
                    {
                        text: 'Dismiss',
                        role: 'cancel'
                    }
                ],
            })
            SetData({ ...data })
        }
    }
    const colors = item.color ? item.color.split(",") : []
    const sizes = item.size ? item.size.split(",") : []

    return <>

        {item.color_status === '1' && <IonItem className='attr-selector'>
            <IonLabel>Color</IonLabel>
            <IonSelect ref={colorSelect} interface="popover" value={colors[0]}>
                {colors.map((str, index) => <IonSelectOption key={index} value={str}>{str}</IonSelectOption>)}
            </IonSelect>
        </IonItem>}
        {item.size_status === '1' && <IonItem className='attr-selector'>
            <IonLabel>Size</IonLabel>
            <IonSelect ref={sizeSelect} interface="popover" value={sizes[0]}>
                {sizes.map((str, index) => <IonSelectOption key={index} value={str}>{str}</IonSelectOption>)}
            </IonSelect>
        </IonItem>}
        <IonButton fill='outline' onClick={onDecreament}><FontAwesomeIcon icon={faMinus} /></IonButton>
        <IonInput onIonChange={onChange} ref={input} value={1} type='number' />
        <IonButton fill='outline' onClick={onIncreament}><FontAwesomeIcon icon={faPlus} /></IonButton>
        <IonButton color='tertiary' className='btn-order' onClick={onAddToCart}><IonText>কার্টে যোগ করুন</IonText></IonButton>
        <IonButton color='danger' className='btn-order' onClick={() => {
            onAddToCart()
            modalRef?.current?.dismiss()
            cartModalRef.current?.present()
        }}><IonText color='light'>অর্ডার কনর্ফাম করুন</IonText></IonButton>
        <IonButtons>
            <ProductWishlistButton id={item.id} />
            <IonButton fill='solid' href='tel:+8801676457427' color='success'><IonIcon slot='start' icon={logoWhatsapp} />কল করুন</IonButton>
            <IonButton fill='solid' color='primary' href={'https://m.me/bbazarbbazarbbazar/?text=' + encodeURIComponent("I would like to order : " + item.name)} target='_blank'>
                <IonIcon slot='start' icon={logoFacebook} />মেসেজ দিন
            </IonButton>
        </IonButtons>
    </>
};

export default QuickViewAddToCart;
