import { Redirect, Route } from 'react-router-dom';
import {
    IonMenu,
    IonPage,
    IonRouterOutlet
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Home from '../pages/Home';
import Menu from '../components/Menu';
import { appSubDir } from '../configs/app';
import Contact from '../pages/Contact';
import Shop from '../pages/products/Shop';
import FlashSale from '../pages/products/FlashSale';
import Product from '../pages/products/Product';
import Category from '../pages/products/Category';
import Author from '../pages/products/Author';
import Subject from '../pages/products/Subject';
import NotFound from '../pages/NotFound';
import NewArrival from '../pages/products/NewArrival';
import GuestInvoiceLoginError from '../pages/GuestInvoiceLoginError';
import Invoices from '../pages/account/Invoices';
import Invoice from '../pages/account/Invoice';
import PaymentVerify from '../pages/payments/PaymentVerify';
import PaymentSuccess from '../pages/payments/PaymentSuccess';
import PaymentFailed from '../pages/payments/PaymentFailed';
import PaymentCancelled from '../pages/payments/PaymentCancelled';
import TransactionsPage from '../pages/account/TransactionsPage';
import TransactionsDetailsPage from '../pages/account/TransactionsDetailsPage';
import GroupLinks from '../pages/account/GroupLinks';
import WishlistPage from '../pages/products/WishlistPage';
import { useAppContext } from '../services/app-context.service';
import ResellerDashboard from '../pages/account/ResellerDashboard';
import Search from '../pages/products/Search';


const PrivateRoutes: React.FC = () => {
    const { user } = useAppContext()
    return <IonReactRouter>
        <IonMenu type='push' id="sidebar" contentId="menu">
            <Menu />
        </IonMenu>
        <input className='dummy' name="email" type="text" />
        <input className='dummy' type="password" />
        <IonPage id='menu'>
            <IonRouterOutlet>
                {/* Public Pages */}
                <Route exact path={appSubDir}>
                    <Home />
                </Route>
                <Route exact path={appSubDir + "contact"}>
                    <Contact />
                </Route>
                <Route exact path={appSubDir + "shop"}>
                    <Shop />
                </Route>
                <Route exact path={appSubDir + "flash-sale"}>
                    <FlashSale />
                </Route>
                <Route exact path={appSubDir + "new-arrival"}>
                    <NewArrival />
                </Route>
                <Route exact path={appSubDir + "product/:slug/item-:ID"}>
                    <Product />
                </Route>
                <Route exact path={appSubDir + "search/:searchKey"}>
                    <Search />
                </Route>
                <Route exact path={appSubDir + "categories/:catID"}>
                    <Category />
                </Route>
                <Route exact path={appSubDir + "authors/:authorID"}>
                    <Author />
                </Route>
                <Route exact path={appSubDir + "categories/:catID/subjects/:subjectID"}>
                    <Subject />
                </Route>
                <Route exact path={appSubDir + "guest-invoice/:encryptedInvoiceID"}>
                    <GuestInvoiceLoginError />
                </Route>
                <Route exact path={appSubDir + "invoices"}>
                    <Invoices />
                </Route>
                <Route exact path={appSubDir + "invoices/:invoiceID"}>
                    <Invoice />
                </Route>
                <Route exact path={appSubDir + "transactions"}>
                    <TransactionsPage />
                </Route>
                <Route exact path={appSubDir + "my-shop"}>
                    <WishlistPage />
                </Route>
                <Route exact path={appSubDir + "wishlist"}>
                    <WishlistPage />
                </Route>
                <Route exact path={appSubDir + "fb-group-links"}>
                    <GroupLinks />
                </Route>
                <Route exact path={appSubDir + "transactions/:date"}>
                    <TransactionsDetailsPage />
                </Route>
                {/* Private Pages */}
                <Route exact path={appSubDir + "payment-verify/:valID"}>
                    <PaymentVerify />
                </Route>
                <Route exact path={appSubDir + "payment/success"}>
                    <PaymentSuccess />
                </Route>
                <Route exact path={appSubDir + "payment/failed"}>
                    <PaymentFailed />
                </Route>
                <Route exact path={appSubDir + "payment/cancelled"}>
                    <PaymentCancelled />
                </Route>
                <Route exact path={appSubDir + "reseller-dashboard"}>
                    {user.role_id === 2 ? <ResellerDashboard /> : <NotFound />}
                </Route>
                <Route>
                    <NotFound />
                </Route>
            </IonRouterOutlet>
        </IonPage>
    </IonReactRouter>
};

export default PrivateRoutes;
