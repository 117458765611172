import { useState } from "react";

const ProgressiveImg: React.FC<any> = (props) => {
    const [error,setError] = useState(false)
    if(error){
        return <img {...props} style={{margin:"0 auto"}} loading='lazy' src='/assets/img/Image-loading-failed.webp' onError={()=>{
            setError(true)
        }}/>
    }else{
        return <img {...props} loading='lazy' onError={()=>{
            setError(true)
        }}/>
    }
};

export default ProgressiveImg;
