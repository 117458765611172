import { apiBaseUrl } from "../../configs/app";
import Product from "../../types/product.type";
import CryptoService from "../crypto.services";
import loginService from "../loginService";

function getKey(productId: number){
    return CryptoService.hash('product-data-' + productId)
}
export function getLocalProduct(productId: number) {
    const localStorageKey = getKey(productId)
    const localData = window.localStorage.getItem(localStorageKey)
    return (localData ? JSON.parse(CryptoService.decrypt(localData)) as Product : null)
}
export function fetchProduct(productId: number) {
    const localStorageKey = getKey(productId)
    let promise:Promise<Response>
    if(loginService.currentUser.token){
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + loginService.currentUser.token);

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        } as RequestInit;
        promise = fetch(apiBaseUrl + 'v3/private/products/' + productId + '/data',requestOptions)
    }else{
        promise = fetch(apiBaseUrl + 'v3/products/' + productId + '/data')
    }
    return promise.then((res) => res.json())
        .then((res:Product) => {
            if (res.id) {
                window.localStorage.setItem(localStorageKey, CryptoService.encrypt(JSON.stringify(res)))
                return res
            } else {
                return null
            }
        })
}
