import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonPopover, IonSelect, IonSelectOption } from "@ionic/react";
import "./CartProduct.scss";
import { CartItem } from "../../types/shop/cart.type";
import shoppingCartService from "../../services/shop/cart.service";
import { useAppContext, useAppContextUpdater } from "../../services/app-context.service";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

type Props = {
    itemIndex: number
    cartItem: CartItem
    children: JSX.Element[]
    className?: string
}
const CartProductEdit: React.FC<Props> = ({ itemIndex, cartItem, children, className }) => {
    const data = useAppContext()
    const SetData = useAppContextUpdater()
    const input = useRef<HTMLIonInputElement>(null)
    const price = useRef<HTMLIonInputElement>(null)
    const colorSelect = useRef<HTMLIonSelectElement>(null)
    const sizeSelect = useRef<HTMLIonSelectElement>(null)
    function onUpdateCart() {
        data.cart = shoppingCartService.shoppingCart
        SetData({ ...data })
    }
    function getInputValue() {
        if (input.current && input.current.value) {
            if (typeof input.current.value == "string") {
                return parseInt(input.current.value) || 0
            } else {
                return input.current.value
            }
        }
        return 0
    }
    function getPrice() {
        if (price.current && price.current.value) {
            if (typeof price.current.value == "string") {
                return parseInt(price.current.value) || 0
            } else {
                return price.current.value
            }
        }
        return cartItem.min_sale_price
    }
    function onPriceChange() {
        const selectedPrice = getPrice()
        if (selectedPrice >= cartItem.min_sale_price) {
            shoppingCartService.updateSatePrice(cartItem, selectedPrice)
        } else {
            shoppingCartService.updateSatePrice(cartItem, cartItem.min_sale_price)
            if (price.current) {
                price.current.value = cartItem.min_sale_price
            }
        }
        onUpdateCart()
    }
    function onChange() {
        const selectedQuantity = getInputValue()
        if (selectedQuantity > 0) {
            shoppingCartService.updateQuantity(itemIndex, selectedQuantity)
        }
        onUpdateCart()
    }
    function onIncreament() {
        shoppingCartService.addToCart(cartItem, 1)
        onUpdateCart()
    }
    function onDecreament() {
        if (cartItem.quantity > 1) {
            shoppingCartService.addToCart(cartItem, -1)
            onUpdateCart()
        }
    }
    function onColorChange() {
        shoppingCartService.shoppingCart.items[itemIndex].selected_color = colorSelect.current?.value
        onUpdateCart()
    }
    function onSizeChange() {
        shoppingCartService.shoppingCart.items[itemIndex].selected_size = sizeSelect.current?.value
        onUpdateCart()
    }
    const colors = cartItem.color ? cartItem.color.split(",") : []
    const sizes = cartItem.size ? cartItem.size.split(",") : []
    return <>
        <div className={className} id={`cart-cartItem-${itemIndex}`}>{children}</div>
        <IonPopover trigger={`cart-cartItem-${itemIndex}`} triggerAction="click">
            <IonContent class="ion-padding">
                <IonItem>
                    <IonButton fill='outline' slot="start" onClick={onDecreament}><FontAwesomeIcon icon={faMinus} /></IonButton>
                    <IonInput ref={input} onIonChange={onChange} debounce={1000} style={{ textAlign: "center" }} value={cartItem.quantity} type='number' />
                    <IonButton fill='outline' slot="end" onClick={onIncreament}><FontAwesomeIcon icon={faPlus} /></IonButton>
                </IonItem>
                {data.user.role_id == 2 && <IonItem>
                    <IonLabel>Price</IonLabel>
                    <IonInput ref={price} onIonChange={onPriceChange} debounce={1000} style={{ textAlign: "center" }} value={cartItem.sale_price} type='number' />
                </IonItem>}
                {cartItem.color_status === '1' && <IonItem>
                    <IonLabel>Color</IonLabel>
                    <IonSelect ref={colorSelect} onIonChange={onColorChange} interface="popover" value={cartItem.selected_color ? cartItem.selected_color : colors[0]}>
                        {colors.map((str, index) => <IonSelectOption key={index} value={str}>{str}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>}
                {cartItem.size_status === '1' && <IonItem>
                    <IonLabel>Size</IonLabel>
                    <IonSelect ref={sizeSelect} onIonChange={onSizeChange} interface="popover" value={cartItem.selected_size ? cartItem.selected_size : sizes[0]}>
                        {sizes.map((str, index) => <IonSelectOption key={index} value={str}>{str}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>}
                <IonButton expand="block">Update</IonButton>
            </IonContent>
        </IonPopover>
    </>

}
export default CartProductEdit
